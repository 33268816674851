import React, { useState } from "react";
import { Section, SubSection } from "~components/text-helpers";
import Latex from "react-latex";
import { graphql, Link } from "gatsby";
import Img, { FixedObject, FluidObject } from "gatsby-image";
import { css } from "@emotion/react";
import { Table } from "antd";
import color from "~styles/color";

import {
  Code,
  Response,
  Remark,
  Warning,
  Attribute,
  IL,
  VSpace,
  SideBySideCode,
  APIMethod,
  APIReferenceiTHOR,
} from "~components/documentation";
import { UnityAPI } from "~components/api-data";

export default function iTHORDocReference({ data, location }) {
  const [highlightedParam, setHighlightedParam] = useState("");
  return (
    <APIReferenceiTHOR columnKey="setObjectStates" pageName="Set Object States">
      <Section sectionTitle="Set Object Poses" emoji="rosette">
        <p>
          <IL>SetObjectPoses</IL> is useful for setting a scene to the exact
          state of a previous initialization without relying on the Object
          Position Randomization’s random seed. Use a previous metadata dump of
          SimObjects to get position/rotation data for each SimObject. Sets up a
          scene according to the provided objects and poses. Objects which are
          specified twice will be copied.
        </p>
        <Warning>
          <p>
            All moveable and pickupable objects will be removed from the scene
            if they are not specified.
          </p>
        </Warning>
        <SideBySideCode
          example={
            <Code>
              {String.raw`controller.step(
  action='SetObjectPoses',
  objectPoses=[
    {
      "objectName": "Alarm_Clock_19",
      "rotation": {
        "y": 270,
        "x": 0,
        "z": 0
      },
      "position": {
        "y": 0.8197357,
        "x": 2.45610785,
        "z": 0.054755792
      }
    },
    {...},
    {
      "objectName": "Alarm_Clock_19",
      "rotation": {
        "y": 270,
        "x": 0,
        "z": 0
      },
      "position": {
        "y": 0.8197357,
        "x": 2.64169645,
        "z": -0.134690791
      }
    }
  ]
)
`}
            </Code>
          }
          argsTitle="Set Object Poses Parameters"
        >
          <Attribute
            hashPrefix="set-object-poses"
            name="objectPoses"
            type="list[dict[str, any]]"
            required={true}
            description={
              <>
                <p>
                  List of object names and their poses. This information can be
                  dumped from the metadata of a prior episode. Each pose must
                  contain keys for
                </p>
                <ul>
                  <li>
                    <b>objectName</b>: The name of the Sim Object reported by
                    the object metadata's <IL>name</IL> parameter.
                  </li>
                  <li>
                    <b>position</b>: Global coordinate position of the object,
                    as reported in the object's <IL>position</IL> metadata.
                  </li>
                  <li>
                    <b>rotation</b>: Local coordinate rotation of the object, as
                    reported in the object's <IL>rotation</IL> metadata.
                  </li>
                </ul>
              </>
            }
          />
        </SideBySideCode>
      </Section>
      <Section sectionTitle="Set Mass Properties" emoji="weight_lifter">
        <APIMethod
          location={location}
          methodKey="SetMassProperties"
          parentAPI={UnityAPI.BaseAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Set Temperature" emoji="thermometer">
        <SubSection
          sectionTitle="Object Type Temperature Decay Time"
          emoji="cooking"
        >
          <p>
            <IL>SetRoomTempDecayTimeForType</IL> changes all objects specified
            by objectType in the current scene to have a new Room Temp Decay
            Time specified by TimeUntilRoomTemp. This can be done to adjust the
            amount of time it takes for specific object types to return to room
            temperature. By default, all objects begin at Room Temperature.
            Objects placed on/in Hot or Cold sources (stove burner, microwave,
            fridge etc.) will have their ObjectTemperature value changed to Hot
            or Cold. If the object is removed from a Hot or Cold source, they
            will return to room temperature over time. The default time it takes
            for an object to decay to Room Temp is 10 seconds.
          </p>
          <SideBySideCode
            example={[
              "controller.step(",
              '    action="SetRoomTempDecayTimeForType",',
              '    objectType="Bread",',
              "    TimeUntilRoomTemp=20.0",
              ")",
            ]}
            argsTitle="Parameters"
          >
            <Attribute
              hashPrefix="object-temp"
              name="objectType"
              type="str"
              description={
                <p>
                  The object type to change the decay timer of. See a full list
                  of Object Types on the Object Types page.
                </p>
              }
            />
            <Attribute
              hashPrefix="object-temp"
              name="TimeUntilRoomTemp"
              type="float"
              required={true}
              description={
                <p>
                  The amount of time it will take for an object to decay from
                  Hot/Cold to Room Temp.
                </p>
              }
            />
          </SideBySideCode>
        </SubSection>
        <SubSection
          sectionTitle="Global Temperature Decay Time"
          emoji="earth_americas"
        >
          <p>
            <IL>SetGlobalRoomTempDecayTime</IL> changes all objects in the
            current scene to have a new Room Temp Decay Time specified by
            TimeUntilRoomTemp. By default, all objects begin at Room
            Temperature. Objects placed on/in Hot or Cold sources (stove burner,
            microwave, fridge etc.) will have their ObjectTemperature value
            changed to Hot or Cold. If the object is removed from a Hot or Cold
            source, they will return to room temperature over time. The default
            time it takes for an object to decay to Room Temp is 10 seconds.
          </p>
          <SideBySideCode
            example={[
              "controller.step(",
              '    action="SetGlobalRoomTempDecayTime",',
              "    TimeUntilRoomTemp=20.0",
              ")",
            ]}
            argsTitle="Parameters"
          >
            <Attribute
              hashPrefix="global-temp"
              name="TimeUntilRoomTemp"
              type="float"
              required={true}
              description={
                <p>
                  The amount of time it will take for an object to decay from
                  Hot/Cold to Room Temp.
                </p>
              }
            />
          </SideBySideCode>
        </SubSection>
        <SubSection sectionTitle="Disable Temperature Decay" emoji="coffee">
          <p>
            <IL>SetDecayTemperatureBool</IL> disables the decay over time of the
            ObjectTemperature of objects. If set to False, objects will not
            decay to room temperature and will remain Hot/Cold even if removed
            from the Hot/Cold source.
          </p>
          <SideBySideCode
            example={[
              "controller.step(",
              "    action='SetDecayTemperatureBool',",
              "    allowDecayTemperature=False",
              ")",
            ]}
            argsTitle=""
          >
            <Attribute
              hashPrefix="temp-decay-bool"
              name="allowDecayTemperature"
              type="bool"
              default="True"
              description={
                <p>Set to allow object Temperatures to decay over time.</p>
              }
            />
          </SideBySideCode>
        </SubSection>
      </Section>
      {/* <Section sectionTitle="Set Object States">
        <Remark>
          This action is in the process of being rewritten to the form:
          <Code
            lines={[
              "controller.step(",
              '    action="SetObjectStates",',
              "    objectId=<objectId>,",
              "    isOpen=True,",
              "    isCooked=True,",
              "    isBroken=True",
              ")",
            ]}
          />
          <p
            css={css`
              margin-top: 10px;
            `}
          >
            This is both more intuitive, and allows more than 1 state to be
            specified. Expect it in a near-term release!
          </p>
        </Remark>
        <SubSection sectionTitle="Make Everything Moveable" emoji="computer">
          <p>
            Use this if running an episode with the intention of realistically
            throwing/pushing/colliding objects around. This action resets
            kinematics of all non-static objects in the scene so that they are
            affected by gravity. This is to ensure immediate physics reactions
            in cases where objects are thrown or pushed into each other and they
            need to bounce and react immediately. Without running this, some
            objects might default to stationary placement, where they will not
            immediately respond to physics.
          </p>
          <Code lines={['controller.step("MakeAllObjectsMoveable")']} />
        </SubSection>
        <SubSection sectionTitle="Make Objects Unbreakable" emoji="computer">
          <p>
            Objects with the Breakable property break by default if enough force
            is applied to them. This means dropping these objects from a high
            enough distance will cause automatic state changes. You can use this
            helper initialization function to set all objects of a given Type to
            unbreakable, preventing this automatic interaction. This example
            sets all Eggs in the scene to be unbreakable unless explicitly using
            the BreakObject action.
          </p>
          <SideBySideCode
            example={[
              "controller.step(",
              '    action="MakeObjectOfTypeUnbreakable",',
              '    objectType="Egg"',
              ")",
            ]}
            argsTitle=""
          >
            <Attribute
              hashPrefix="unbreakable"
              name="objectType"
              type="str"
              required={true}
              description={
                <>
                  The name of the breakable{" "}
                  <Link to="../object-types">object type</Link> in the scene.
                </>
              }
            />
          </SideBySideCode>
        </SubSection>
      </Section> */}
      <Section sectionTitle="Hiding Objects" emoji="ghost">
        <Warning>
          <p>
            Hiding an object may cause unintended interactions with other
            objects after an object is disabled. For example, a table with a
            Plate and Apple resting on top of it will cause both the Plate and
            Apple to fall to the floor if the table object supporting them is
            disabled.
          </p>
        </Warning>
        <SubSection sectionTitle="Remove" emoji="x">
          <p>
            <IL>RemoveFromScene</IL> completely destroys an removes an object
            from the scene. This action cannot be undone (without resetting the
            scene).
          </p>
          <SideBySideCode
            example={[
              "controller.step(",
              '    action="RemoveFromScene",',
              '    objectId="Mug|+0.25|-0.27|+1.05"',
              ")",
            ]}
            argsTitle="Remove from Scene Parameters"
          >
            <Attribute
              hashPrefix="remove"
              name="objectId"
              type="str"
              required={true}
              description={
                <p>The unique identifier of the object in the scene.</p>
              }
            />
          </SideBySideCode>
        </SubSection>
        <SubSection sectionTitle="Disable" emoji="bat">
          <p>
            <IL>DisableObject</IL> disables an object from being visible in the
            scene. Unlike the RemoveFromScene action, this does not permanently
            destroy an object in the scene, as it can be toggled back on later
            in the episode using EnableObject.
          </p>
          <SideBySideCode
            example={[
              "controller.step(",
              '    action="DisableObject",',
              '    objectId="DiningTable|+1.0|+1.0|+1.0"',
              ")",
            ]}
            argsTitle="Disable Objet Parameters"
          >
            <Attribute
              hashPrefix="disable-object"
              name="objectId"
              required={true}
              type="str"
              description={
                <p>
                  The string id of the sim object to disable. Note that this may
                  cause unintended interactions with other objects after an
                  object is disabled. For example, a table with a Plate and
                  Apple resting on top of it will cause both the Plate and Apple
                  to fall to the floor if the table object supporting them is
                  disabled.
                </p>
              }
            />
          </SideBySideCode>
        </SubSection>
        <SubSection sectionTitle="Enable" emoji="sunglasses">
          <p>
            <IL>EnableObject</IL> activates an object if it has been previously
            disabled by DisableObject.
          </p>
          <SideBySideCode
            example={[
              "controller.step(",
              '    action="EnableObject",',
              '    objectId="DiningTable|+1.0|+1.0|+1.0"',
              ")",
            ]}
            argsTitle="Enable Objet Parameters"
          >
            <Attribute
              hashPrefix="disable-object"
              name="objectId"
              required={true}
              type="str"
              description={
                <p>
                  The string id of the sim object to reactivate. Note that this
                  may cause unintended interactions with other objects after an
                  object is reactivated. Enabled objects will return to their
                  original location, which may cause clipping or weird collision
                  with other objects if another sim object or the agent has
                  moved into the area where the enabled object is.
                </p>
              }
            />
          </SideBySideCode>
        </SubSection>
      </Section>
    </APIReferenceiTHOR>
  );
}

export const query = graphql`
  fragment FluidImage on File {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  query {
    horizon: file(relativePath: { eq: "horizon-angles.jpg" }) {
      ...FluidImage
    }
    dimensions: file(relativePath: { eq: "dimensions.jpg" }) {
      ...FluidImage
    }
  }
`;
